/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Option } from '../components/Option';
import ObjectUtils from '../utils/ObjectUtils';
import { useLocalStorageState } from './useLocalStorageState';

type OutsideOption = Option<string | number, boolean>;
type OutsideFormat = Record<string, OutsideOption[]>;
type InsideFormat = Record<keyof OutsideFormat, (string | number)[]>;

const capitalize = <T extends string>(value: T) => (value.charAt(0).toUpperCase() + value.substring(1)) as Capitalize<T>;

export const useStoredFilters = <TDefaults extends OutsideFormat>(pageId: string, filterDefaultsMemo: TDefaults) => {
  const toInsideFormat = useCallback((data: TDefaults) => {
    const result: InsideFormat = {};
    for (const filter of Object.keys(data)) {
      result[filter as any] = data[filter].filter((x) => x.value).map((x) => x.id);
    }

    return result;
  }, []);

  const toOutsideFormat = useCallback(
    (data: InsideFormat) => {
      const result = ObjectUtils.DeepClone(filterDefaultsMemo);
      for (const filter of Object.keys(data)) {
        for (const { id } of result[filter]) {
          const f = result[filter].find((x) => x.id === id);
          if (!f) continue;

          const found = data[filter].indexOf(id) > -1;
          f.value = found;
        }
      }

      return result;
    },
    [filterDefaultsMemo],
  );

  const [ls, setLs] = useLocalStorageState(`${pageId}-filters`, () => toInsideFormat(filterDefaultsMemo));
  const prevValues = useRef(ls);

  useEffect(() => {
    prevValues.current = ls;
  }, [ls]);

  const filters = useMemo(() => {
    return toOutsideFormat(ls);
  }, [ls, toOutsideFormat]);

  const setFilters = useCallback(
    (filterName: keyof typeof filterDefaultsMemo, values: OutsideOption[]) => {
      setLs((prev) => ({ ...prev, [filterName]: toInsideFormat({ [filterName]: values } as any)[filterName as string] }));
    },
    [setLs, toInsideFormat],
  );

  const setters = useMemo(() => {
    const result: any = {};
    for (const key of Object.keys(filterDefaultsMemo)) {
      result[`set${capitalize(key as string & keyof typeof filterDefaultsMemo)}`] = (
        values: OutsideOption[] | ((prev: OutsideOption[]) => OutsideOption[]),
      ) => setFilters(key, typeof values === 'function' ? values(toOutsideFormat(prevValues.current)[key]) : values);
    }

    return result as Record<
      `set${Capitalize<string & keyof typeof filterDefaultsMemo>}`,
      (values: OutsideOption[] | ((prev: OutsideOption[]) => OutsideOption[])) => void
    >;
  }, [filterDefaultsMemo, setFilters, toOutsideFormat]);

  const setAllDefault = useCallback(() => {
    setLs(toInsideFormat(filterDefaultsMemo));
  }, [filterDefaultsMemo, setLs, toInsideFormat]);

  const anyApplied = useMemo(() => {
    return Object.values(ls).some((x) => x.length);
  }, [ls]);

  return [filters, setters, { clearFilters: setAllDefault, hasFiltersApplied: anyApplied }] as const;
};
